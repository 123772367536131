<template>
  <div>
    <CRow>
      <CCol md="12">
        <CCard>
          <CCardHeader>
            Fee 
            
          </CCardHeader>
          <CCardBody>
              <CCard accent-color="secondary" >
                <CCardBody style="font-size: 11px;">
                    <div class="text-left"><strong>Owner</strong>
                    <br><small>Fee Total : <strong>{{ numberWithCommas(+depo- +wd1)}} USDT</strong></small>
                    <br><small>Fee Diambil : {{wd1}}</small>
                    </div>
                    <br>
                    <button type="button"  @click="showWD('TFva74a1oTnymrYHkY98LvLXMq7ad8m8ua', 'Imamsarkowi81@gmail.com', depo, wd1)" class="float-right btn btn-info form-control-sm" style="margin-right: 15px;">Transfer</button>
                    
                  <!-- <div > {{row.link}}</div> -->
                </CCardBody>
              </CCard> 
              <CCard accent-color="secondary" >
                <CCardBody style="font-size: 11px;">
                    <div class="text-left"><strong>Admin 1</strong>
                    <br><small>Fee Total : <strong> {{ numberWithCommas(+depo- +wd2)}} USDT</strong></small>
                    <br><small>Fee Diambil : {{wd2}}</small>
                    </div>
                    <br>
                    <button type="button"  @click="showWD('THGBftxkwKoPJhfNDhpSY7hcyB9SdYjfxY', 'gromebanget@gmail.com', depo, wd2)" class="float-right btn btn-info form-control-sm" style="margin-right: 15px;">Transfer</button>
                    
                  <!-- <div > {{row.link}}</div> -->
                </CCardBody>
              </CCard> 
              <CCard accent-color="secondary" >
                <CCardBody style="font-size: 11px;">
                    <div class="text-left"><strong>Admin 2</strong>
                    <br><small>Fee Total : <strong> {{ numberWithCommas(+depo- +wd3)}} USDT</strong></small>
                    <br><small>Fee Diambil : {{wd3}}</small>
                    </div>
                    <br>
                    <button type="button"  @click="showWD('THGBftxkwKoPJhfNDhpSY7hcyB9SdYjfxY', 'ridwanname@gmail.com', depo, wd2)" class="float-right btn btn-info form-control-sm" style="margin-right: 15px;">Transfer</button>
                    
                  <!-- <div > {{row.link}}</div> -->
                </CCardBody>
              </CCard> 
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>

    <CModal
      :show.sync="modalWD"
      :no-close-on-backdrop="true"
      :centered="true"
      title="Confirmation"
      color="dark"
    >
      Are you sure want to withdrawal all fee ({{email}}) with address : {{address}} ?
      <template #header>
        <h6 class="modal-title">Custom smaller modal title</h6>
        <CButtonClose @click="modalWD = false" class="text-white"/>
      </template>
      <template #footer>
        <CButton @click="modalWD = false" color="dark">Cancel</CButton>
        <CButton @click="addWD" color="success">Ok</CButton>
      </template>
    </CModal>


  <CModal title="Success" :show.sync="mySuccess" size="sm">
    Success! You have withdrawal fee system for Owner/Admin1/Admin2
  </CModal>


  <CModal title="Failed" :show.sync="myError" size="sm">
    Failed! Your withdrawal request is failed, please check your connection...
  </CModal>

    <CSpinner v-show="mySpinner" color="primary" style="position:fixed;left: 50%;top:50%"/>
  </div>
</template>
 
<script>

import { CChartBar } from '@coreui/vue-chartjs'
import * as Charts from './index.js'
import 'jquery/dist/jquery.min.js';
// import 'bootstrap/dist/css/bootstrap.min.css';
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import axios from 'axios';
import $ from 'jquery'; 



export default {
  // methods: {
  //   numberWithCommas(x) {
  //       return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  //   }
  // },
  components: {
    ...Charts,CChartBar ,
  },
  filters: {
    rupiah: function(value) {
      // return value.toUpperCase();
      // Create our number formatter.
      var formatter = new Intl.NumberFormat('id-ID', {
        style: 'currency',
        currency: 'IDR',

        // These options are needed to round to whole numbers if that's what you want.
        //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
        //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
      });

      return formatter.format(value); /* $2,500.00 */
    }
  },
  computed : {
    years () {
      const year = new Date().getFullYear()
      return Array.from({length: year - 2019}, (value, index) => new Date().getFullYear() - index)
    },
    defaultDatasets () {
      return [
        {
          label: 'Rencana',
          backgroundColor: '#E0922E',
          data: this.rencanas
        },
        {
          label: 'Realisasi',
          backgroundColor: '#5CA83F',
          data: this.realisasis
        },
        // {
        //   label: 'Deviasi',
        //   backgroundColor: 'rgb(228,102,81,0.9)',
        //   data: this.deviasis
        // }
      ]
    },
    defaultOptions () {
      return {
        scales: {
            xAxes: [{
                ticks: {
                    autoSkip: false,
                    maxRotation: 90,
                    minRotation: 90
                }
            }]
        }
      }
    }
  },
  mounted(){

    this.loadData();
  },
  data: function() {
        return {
            products:[],
            labels:[],
            rencanas:[],
            realisasis:[],
            deviasis:[],
            devcolors:[],
            depo:0,
            depoasli : 0,
            wd:0,
            wd1:0,
            wd2:0,
            wd3:0,
            em1:"Imamsarkowi81@gmail.com",
            em2:"gromebanget@gmail.com",
            em3:"",
            deporeal:0,
            mySpinner:false,
            modalWD:false,
            myError:false,
            mySuccess:false,
            email : "",
            address:"",
            tahun: new Date().getFullYear(),
        }
  },
  methods: {
    numberWithCommas: function(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    showWD: function (add,em, dp, wed) {
      this.email = em;
      this.address = add;
      this.modalWD = true;
      this.depoasli = +dp - +wed;
    },
    addWD: function (event) {
      this.modalWD = false;
      this.mySpinner = true;
      // Simple POST request with a JSON body using axios
      const stk = {
        mid: 0,
        email: this.email,
        address_w: this.address,
        amount_w: this.depoasli,
      };
        axios
          .post(
            process.env.VUE_APP_BASE_URL+"api/public/index.php/addwithdrawalfee/?key=$2y$10$AnZ411EfYcBHTEMMYbXni.7HuKm5nf4fCleVEKMPs9dsDRKlrLCPu",
            stk
          )
          .then((response) => {
            // console.log(response);
            // alert(JSON.stringify(response.data));
            // this.tabs = response.data;/
            this.mySpinner = false;
            if (response.data.error == "ok") {
                this.modalWD =false;
            this.loadData;
            this.mySuccess = true;
              

            } else {
              
                this.modalWD =false;
                this.myError = true;
              
              
            }
          });
      
    },
    loadData: function() {
      axios
            .get(
              process.env.VUE_APP_BASE_URL+"api/public/index.php/dashboardstat/0?key=$2y$10$AnZ411EfYcBHTEMMYbXni.7HuKm5nf4fCleVEKMPs9dsDRKlrLCPu",
              false
            )
            .then((response) => {
              // if (response.data == "sukses") {
                
                // this.wallet = parseFloat(response.data.wallet);
                this.deporeal = Math.round(parseFloat(response.data.fee)*0.01);
                // alert(this.deporeal);
                // this.wd = parseFloat(response.data.feewd) ;
                this.depo = parseFloat(response.data.fee) ;
                this.depo = Math.round(this.depo * 0.01);
                
            });
    

            axios
            .get(
              process.env.VUE_APP_BASE_URL+"api/public/index.php/feewd/"+this.em1+"?key=$2y$10$AnZ411EfYcBHTEMMYbXni.7HuKm5nf4fCleVEKMPs9dsDRKlrLCPu",
              false
            )
            .then((response) => {
              // if (response.data == "sukses") {
                
                this.wd1 = parseFloat(response.data.feewd) ;
                
            });

            axios
            .get(
              process.env.VUE_APP_BASE_URL+"api/public/index.php/feewd/"+this.em2+"?key=$2y$10$AnZ411EfYcBHTEMMYbXni.7HuKm5nf4fCleVEKMPs9dsDRKlrLCPu",
              false
            )
            .then((response) => {
              // if (response.data == "sukses") {
                
                this.wd2 = parseFloat(response.data.feewd) ;
                
            });
      
    }
  }
}
</script>